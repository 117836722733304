<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submitResetEmailForm)">
      <h1 class="text-h2 font-weight-bold mb-12">Reset <br> Password</h1>
      <div class="error pa-2 mb-4 text--white" v-if="resetError">{{ resetError }}</div>

      <ValidationProvider mode="eager" tag="label" name="Email" rules="required|email" v-slot="{ errors }">
        <div class="font-weight-bold text-body-1 mb-2">Email</div>
        <v-text-field
          type="email"
          outlined
          hide-details="auto"
          v-model="email"
          placeholder="Email"
        ></v-text-field>
        <div class="font-weight-bold text-body-1 text--error">{{ errors[0] }}</div>
      </ValidationProvider>

      <div class="d-flex justify-space-between align-center mt-8">
        <a role="button" @click="$emit('showLoginForm')" class="text-body-1 font-weight-bold mr-2" style="color:#ff5d28;">
          &lt; Back to login
        </a>
        <v-btn
          type="submit"
          rounded
          dark
          depressed
          large
          color="#ff5d28"
          class="text-h6 font-weight-bold text-capitalize px-13 py-7"
        >
          Reset password
        </v-btn>
      </div>
    </v-form>

  </ValidationObserver>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {recoverEmailRequest} from "@/api/authorizations.api";

export default {
  name: 'ResetEmailForm',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    email: '',
    resetError: '',
    validationsTimeout: null,
  }),
  methods: {
    async submitResetEmailForm() {
      const response = await recoverEmailRequest({email: this.email});
      if (response.status === 404) {
        this.resetError = response.data.message;
      } else {
        this.$emit('showResetStatus', {
          success: true,
          message: 'Check your email for further instructions',
        });
        this.$emit('showLoginForm');
      }
    },
  }
}
</script>
