<template>
  <div class="login-page">

    <div class="login-page__column">
      <div class="login-page__header">
        <router-link :to="{name: 'login'}">
          <img src="@/assets/img/login_syqor_logo.svg" alt="Syqor" height="45">
        </router-link>
      </div>

      <div class="login-page__form-wrap">
        <div class="login-page__form">
          <ValidationObserver v-slot="{ handleSubmit }" v-if="loginForm">
            <v-form @submit.prevent="handleSubmit(submitLoginForm)">
              <h1 class="text-h2 font-weight-bold mb-12">Log In</h1>

              <div class="error pa-2 mb-4  text--white" v-if="loginError">{{ loginError }}</div>
              <div class="success pa-2 mb-4 text--white" v-if="resetStatus.success">{{ resetStatus.message }}</div>

              <ValidationProvider mode="eager" tag="label" name="Email" rules="required|email" v-slot="{ errors }">
                <div class="font-weight-bold text-body-1 mb-2">Email</div>
                <v-text-field
                  type="email"
                  outlined
                  hide-details="auto"
                  v-model="credentials.email"
                  placeholder="Email"
                ></v-text-field>
                <div class="font-weight-bold text-body-1 text--error">{{ errors[0] }}</div>
              </ValidationProvider>

              <ValidationProvider mode="eager" tag="label" name="Password" rules="required" v-slot="{ errors }">
                <div class="font-weight-bold text-body-1 mt-6 mb-2">Password</div>
                <v-text-field
                  type="password"
                  outlined
                  hide-details="auto"
                  v-model="credentials.password"
                  placeholder="Password"
                ></v-text-field>
                <div class="font-weight-bold text-body-1 text--error">{{ errors[0] }}</div>
              </ValidationProvider>

              <div class="d-flex justify-space-between align-center mt-8">
                <a role="button" @click="showResetEmailForm" class="text-body-1 font-weight-bold mr-2"
                   style="color:#ff5d28;">
                  Forgot password?
                </a>
                <v-btn
                  type="submit"
                  rounded
                  dark
                  depressed
                  large
                  color="#ff5d28"
                  class="text-h6 font-weight-bold text-capitalize px-13 py-7"
                >
                  Log In
                </v-btn>
              </div>
            </v-form>
          </ValidationObserver>

          <reset-email-form
            v-if="resetEmailForm"
            @showLoginForm="showLoginForm"
            @showResetStatus="showResetStatus"
          ></reset-email-form>

          <reset-password-form
            v-if="resetPasswordForm"
            @showLoginForm="showLoginForm"
            @showResetStatus="showResetStatus"
          ></reset-password-form>

        </div>
      </div>
    </div>

    <div class="login-page__column login-page__demo">
      <img class="login-page__demo-img login-page__demo-pc" src="@/assets/img/login_demo_pc.png"
           alt="Syqor erp PC demo image">
      <img class="login-page__demo-img login-page__demo-mobile" src="@/assets/img/login_demo_mobile.png"
           alt="Syqor erp mobile demo image">
    </div>

  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {extend} from 'vee-validate';
import {required, email} from 'vee-validate/dist/rules';
import ResetEmailForm from "@/views/login/ResetEmailForm";
import ResetPasswordForm from "@/views/login/ResetPasswordForm";

extend('email', {
  ...email,
  message: '{_field_} is not a valid email address',
});

extend('required', {
  ...required,
  message: '{_field_} cannot be blank.',
});

export default {
  name: 'LoginPage',
  components: {
    ValidationObserver,
    ValidationProvider,
    ResetEmailForm,
    ResetPasswordForm
  },
  data: () => ({
    credentials: {
      email: '',
      password: '',
    },
    loginError: '',
    loginForm: true,
    resetStatus: {
      success: false,
      message: '',
    },
    resetEmailForm: false,
    resetPasswordForm: false,
  }),
  created() {
    if (this.$route.query.password_reset_token) {
      this.loginForm = false;
      this.resetPasswordForm = true;
    }
  },
  methods: {
    async submitLoginForm() {
      const response = await this.$store.dispatch('login', this.credentials);
      if (response) {
        this.loginError = response.message;
      }
    },

    showResetEmailForm() {
      this.loginForm = false;
      this.resetEmailForm = true;
    },

    showLoginForm() {
      this.loginForm = true;
      this.resetEmailForm = false;
    },

    showResetStatus(data) {
      this.resetStatus = {...data};
    }
  },
  watch: {
    '$route.query.password_reset_token': function (val) {
      if (!val) {
        this.loginForm = true;
        this.resetPasswordForm = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-page {
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  &__column {
    flex: 1 0 0;
  }

  &__header {
    margin: 28px 0 0 6%;
  }

  &__form-wrap {
    min-width: 460px;
    padding-left: 17%;
    padding-top: 10%;
  }

  &__form {
    max-width: 490px;
    margin: 0 15px;
    width: 100%;
  }

  &__demo {
    display: flex;
    overflow: hidden;
    max-width: 960px;
  }

  &__demo-img {
    max-width: 100%;
    height: 100%;
  }

  &__demo-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .login-page {
    flex-direction: column;
    justify-content: center;

    &__form-wrap {
      display: flex;
      justify-content: center;
      min-width: auto;
      padding-left: 0;
    }

    &__demo {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      margin-top: 95px;
    }

    &__demo-pc {
      display: none;
    }

    &__demo-mobile {
      display: block;
    }
  }
}

@media only screen and (max-width: 470px) {
  .login-page__header {
    margin-left: 15px;
  }
}
</style>

<style lang="scss">
.login-page {
  .text--error {
    color: #ff5252;
  }

  .text--white {
    color: #fff;
  }
}
</style>
